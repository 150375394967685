import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import gpaylogo from "../assets/gpaylogo.png"
const Navbar = () => {
  return (
    <div style={{
        display:"flex",
        justifyContent:"space-between",
        padding:"15px",
        alignItems:"center",
    }}>
        <div style={{
            display:'flex',
             gap:"10px",
             alignItems:"center"
         }}>
            <MenuIcon style={{color:"#3a82f6"}}/>
             <img src={gpaylogo} alt="gpay" style={{width:"170px"}}/>
        </div>
            <AccountCircleIcon sx={{marginTop:"4px"}} style={{color:"#3a82f6"}}/>
    </div>
  )
}

export default Navbar