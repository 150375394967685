export const jioplans=[
    {
        id:1,
        data:"1.5 GB/day",
        validity:"84 days",
        calls:"Unlimited",
        mrp:749,
        price:149,
    },
    {
        id:2,
        data:"2.0 GB/day ",
        validity:"84 days",
        calls:"Unlimited",
        mrp:999,
        price:189,
    },
    {
        id:3,
        data:"3.0 GB/day ",
        validity:"84 days",
        calls:"Unlimited",
        mrp:1299,
        price:259,
    },
    {
        id:4,
        data:"2.0 GB/day ",
        validity:"6 months",
        calls:"Unlimited",
        mrp:1999,
        price:289,
    },
    {
        id:5,
        data:"2.0 GB/day ",
        validity:"12 months",
        calls:"Unlimited",
        mrp:2499,
        price:389,
    },
    
  
]