import { Link, useLocation, useParams } from "react-router-dom";
import jiologo from "../assets/jiologo.png";
import airtellogo from "../assets/airtellogo.jpg"
import vilogo from "../assets/vilogo.png"
import bsnllogo from "../assets/bsnllogo.png"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import btbox from '../assets/btbox.jpeg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { jioplans } from "../Plans/plans";
import Navbar from "../components/Navbar";
import RechargeCard from "./RechargeCard";
export default function Rechargeplans() {
  const location = useLocation();
  const {phoneNumber}=useParams()
  const {mobileOperator}=useParams()

  // Use the received data here


  return (
    <div>
      <Navbar/>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
       
        


      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "14px",
          padding: "0px 10px 0px 10px",
          background: "#f1f4fa",
        }}
      >
        <p>
          Recharging for <b>{phoneNumber}</b>
        </p>
        <Link style={{ textDecoration: "none" }} to="/">
          change
        </Link>
      </div>
       <div style={{
         display:"flex",
         flexDirection:"column",
         padding:"10px",
         gap:"15px"
         
       }}>

        <RechargeCard  mobileOperator={mobileOperator}/> 

       </div>
    </div>
  );
}
