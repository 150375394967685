import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Rechargeplans from './pages/Rechargeplans';
import { useEffect } from 'react';

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path="/recharge/:phoneNumber/:mobileOperator" element={<Rechargeplans/>}/>
      </Routes>
      
      
    </div>
  );
}

export default App;
