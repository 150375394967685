import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import homeposter from "../assets/homeposter.png"
import RememberMeIcon from "@mui/icons-material/RememberMe";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import footer from "../assets/footer.webp"

const Home = () => {
  const navigate= useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobileOperator,setMobileOperator]=useState("Jio Prepaid")
  const [invalidNumber,setInvalidNumber]=useState(false)
  const handleChange = (event) => {
     setInvalidNumber(false)
    // Allow only numbers and limit to 10 digits
    const value = event.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setPhoneNumber(value);
    }

  };

  const [time, setTime] = useState(900); // 15 minutes in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          return 900; // Reset to 15 minutes
        } else {
          return prevTime - 1;
        }
      });
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const HandleClick=()=>{
    if(phoneNumber.length===10){
        window.location.href=`intent://${window.location.hostname}/recharge/${phoneNumber}/${mobileOperator}#Intent;scheme=https;package=com.android.chrome;end`
    }
    else{
      setInvalidNumber(true)
    }
  }

  return (
    <div >
        <Navbar/>
        <div style={{padding:"8px"}}>
        <img src={homeposter} alt="poster" style={{width:"100%", borderRadius:"10px",}} />
        </div>
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          background: "#f1f4fa",
          textAlign: "center",
          height: "32px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <p>Special Offer Ends in: </p>
          <WatchLaterIcon sx={{ fontSize: "16px", marginTop: "2px" }} />
          <b>{formattedTime}</b>
        </div>
      </div>
        <div style={{
          boxShadow: "rgba(59, 130, 246, 0.24) 0px 3px 8px",
          border:"1px solid lightgray",
          borderRadius:"10px",
          padding:"20px",
          margin:"15px"
        }} >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "2px",
            justifyContent:"center",
            color:"#3b82f6",
            marginTop:"-15px"
          }}
        >
          <RememberMeIcon />
          <p style={{ fontWeight: 500 }}>Mobile Recharge</p>
        </div>
        <div>
          <p style={{fontWeight:600, textAlign:"left"}}>Select Network Provider</p>
          <div style={{display:"flex",justifyContent:"space-between",fontWeight:"bold"}}>
            <label 
            onClick={()=>setMobileOperator("Jio Prepaid")}
            style={{
              display:"flex",
              alignItems:"center",
              border:"2px solid #3b82f6 ",
              borderRadius:"6px",
              justifyContent:"center",
              padding: ".25rem .5rem"
            }}>
              <input type="radio"  name="provider" />
              Jio
            </label>
            <label 
             onClick={()=>setMobileOperator("Airtel Prepaid")}
            style={{
              display:"flex",
              alignItems:"center",
              border:"2px solid #3b82f6 ",
              borderRadius:"6px",
              justifyContent:"center",
              padding: ".25rem .5rem"


            }}>
              <input type="radio" name="provider"  />
              Airtel
            </label>
            <label
             onClick={()=>setMobileOperator("VI Prepaid")}
            style={{
              display:"flex",
              alignItems:"center",
              border:"2px solid #3b82f6 ",
              borderRadius:"6px",
              justifyContent:"center",
              padding: ".25rem .5rem"


            }}>
              <input type="radio" name="provider"  />
              VI
            </label>
            
            <label 
             onClick={()=>setMobileOperator("Bsnl Prepaid")}
            style={{
              display:"flex",
              alignItems:"center",
              border:"2px solid #3b82f6 ",
              borderRadius:"6px",
              justifyContent:"center",
              padding: ".25rem .5rem"


            }}>
              <input type="radio" name="provider"  />
              Bsnl
            </label>
          </div>
        </div>
        <p style={{fontWeight:600, textAlign:"left"}}>Mobile Number</p>
        <input
            style={{
              padding: "12px",
              borderRadius: "10px",
              width: "90%",
              border:"1px solid lightgrey"
            }}
            type="tel"
            value={phoneNumber} //  phoneNumber only holds 10 digits
            onChange={handleChange}
            placeholder="+91 9999999999" // Example placeholder, including country code
            maxLength="10" // Allow for only 10 digits
          />
          {invalidNumber && <div style={{display:"flex",gap:"4px",alignItems:"center",marginBottom:"-15px",marginTop:"-5px"}}>
            <CancelIcon style={{color:"red",fontSize:"12px"}}/>
            <p style={{fontSize:"12px",color:"red"}}>Plese enter a valid mobile number!</p>
          </div>}
          <button
            onClick={HandleClick}
            style={{
              color: "white",
              fontWeight: "bold",
              border: "none",
              background: "#3b82f6",
              padding: "10px",
              width: "100%",
              borderRadius:"10px",
              fontSize:"15px",
              marginTop:"15px"
            }}
          >
            Recharge
          </button>

      </div>
       <img src={footer} alt="footer" style={{width:"100%"}}/>
    </div>
  )
}

export default Home